// @flow
import tvgConf from "@tvg/conf";
import isMobile from "@tvg/utils/mobileUtils";
import { UserProps } from "@tvg/salesforce/src/types";
import type { UserInfo } from "@tvg/types/User";
import { openExternalApp } from "./mediatorUtils";

export const costumerSupportURL: string = "redirectEngine?type=messageUs";

export const getLiveChatURL = (user: UserProps) => {
  if (typeof window === "undefined") {
    return "";
  }

  return `${window.location.protocol}//${
    window.location.host
  }/${costumerSupportURL}${
    user?.logged && isMobile(tvgConf().product)
      ? `&user=${encodeURI(JSON.stringify(user))}`
      : ""
  }`;
};

export const openLiveChat = (user: UserProps) => {
  openExternalApp(getLiveChatURL(user));
};

export const getUserDetails = (logged: boolean, userData: UserInfo) => ({
  logged,
  firstName: userData?.firstName,
  lastName: userData?.lastName,
  accountNumber: userData?.accountNumber,
  emailAddress: userData?.emailAddress,
  state: userData?.homeAddress?.stateAbbr
});
