// @flow

import React, { PureComponent, type Node } from "react";
import { noop, bindAll, get } from "lodash";
import type { UnaryFn, BinaryFn } from "@tvg/types/Functional";
import { success, warning, eyeShow, eyeHide } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import type { InputTypeEnum } from "../../_atom/Input/types";
import {
  InputWrapper,
  ButtonShowHide,
  InputLabel,
  InputContainer,
  StatusIcon,
  WarningMessage,
  TextInput,
  PasswordInput
} from "./styled-components";

import Icon from "../../_static/Icons";

export type LoginFieldStatus = "success" | "error" | null;

const getIcon = (status: "success" | "error", qaLabel: string) => {
  const iconProps =
    status === "success"
      ? { icon: success, color: buildColor("green", "600") }
      : {
          icon: warning,
          color: buildColor("orange", "600")
        };
  return (
    <StatusIcon
      icon={iconProps.icon}
      color={iconProps.color}
      size={16}
      qaLabel={`${qaLabel}${status}`}
    />
  );
};

export type Props = {
  /**
   * Type of input
   */
  type: InputTypeEnum,
  /**
   * Is input hs error layout
   */
  status: LoginFieldStatus,
  /**
   * change handler
   */
  onChangeHandler: BinaryFn<string, string, void>,
  /**
   * onBlur handler
   */
  onBlurHandler: UnaryFn<string, void>,
  /**
   * callback for pressing the GO button
   */
  enterKeyHandler: UnaryFn<SyntheticKeyboardEvent<*>, void>,
  /**
   * displayed text
   */
  value: string,
  /**
   * placeholder to show
   */
  placeholder: string,
  /**
   * placeholder is more opacity
   */
  opacityPlaceholder: boolean,
  /**
   * data-qa-label
   */
  qaLabel: string,
  /**
   * should show button for type changing
   */
  isButtonShown: boolean,
  /**
   * label to show above the input
   */
  label: Node,
  /**
   * Control input value length
   */
  maxLength?: string
};

type State = {
  isPasswordType: boolean
};

export default class InputMolecule extends PureComponent<Props, State> {
  static defaultProps = {
    type: "text",
    status: null,
    onChangeHandler: noop,
    onBlurHandler: noop,
    enterKeyHandler: noop,
    value: "",
    placeholder: "",
    opacityPlaceholder: false,
    qaLabel: "",
    label: "",
    isButtonShown: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isPasswordType: this.props.type === "password"
    };

    bindAll(this, [
      "changeInputType",
      "renderLabel",
      "renderInput",
      "renderWarningMsg"
    ]);
  }

  changeInputType(e: MouseEvent) {
    e.preventDefault();
    this.setState({
      isPasswordType: !this.state.isPasswordType
    });
  }

  renderLabel() {
    const { type, qaLabel, label } = this.props;
    return (
      <InputLabel htmlFor={`${type}Login`} data-qa-label={`${qaLabel}Label`}>
        {label}
      </InputLabel>
    );
  }

  renderInput() {
    const Element = !this.state.isPasswordType ? TextInput : PasswordInput;
    const field = this.props.isButtonShown ? "password" : "username";

    const [show, hide] = [
      <Icon
        icon={eyeShow}
        color={buildColor("white", "100")}
        stroke={buildColor("blue_accent", "500")}
      />,
      <Icon
        icon={eyeHide}
        color={buildColor("white", "100")}
        stroke={buildColor("blue_accent", "500")}
      />
    ];

    return (
      <InputWrapper>
        <Element
          type={this.state.isPasswordType ? "password" : "text"}
          placeholder={this.props.placeholder}
          opacityPlaceholder={this.props.opacityPlaceholder}
          value={this.props.value}
          onChange={(e) => this.props.onChangeHandler(field, e.target.value)}
          onBlur={this.props.onBlurHandler}
          id={`${this.props.type}Login`}
          isError={this.props.status === "error"}
          isPassword={this.props.type === "password"}
          data-qa-label={`${this.props.qaLabel}Input`}
          key={this.props.qaLabel}
          onKeyPress={this.props.enterKeyHandler}
          maxLength={this.props.maxLength && this.props.maxLength}
        />
        {this.props.isButtonShown && (
          <ButtonShowHide
            onClick={this.changeInputType}
            type="tertiary"
            qaLabel="passwordBtn"
          >
            {this.state.isPasswordType ? show : hide}
          </ButtonShowHide>
        )}
      </InputWrapper>
    );
  }

  renderWarningMsg() {
    const { qaLabel, label, status } = this.props;
    return (
      status === "error" && (
        <WarningMessage data-qa-label={`${qaLabel}ErrMsg`}>
          {status && getIcon(status, qaLabel)} Please insert a valid&nbsp;
          {React.isValidElement(label)
            ? // $FlowFixMe
              get(label, "props.text", "")
            : label}
        </WarningMessage>
      )
    );
  }

  render() {
    return (
      <InputContainer data-qa-label={`${this.props.qaLabel}Section`}>
        {this.renderLabel()}
        {this.renderInput()}
        {this.renderWarningMsg()}
      </InputContainer>
    );
  }
}
